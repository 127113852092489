<template>
  <v-container fluid class="down-top-padding pt-0">
    <v-row>
      <v-col cols="12">
        <div>
          <!-- :expanded.sync="expanded" -->
          <!-- show-expand -->
          <v-data-table
            :loading="loading"
            class="elevation-0 pb-4 pt-4"
            :headers="headers"
            :items="paymenthistory"
            item-key="id"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :disable-sort="true"
          >
            <template v-slot:top class="px-3">
              <v-toolbar flat class="mb-0 px-3" v-if="paymentSubscription">
                Current Start Date -  End date: &nbsp;<b>{{ paymentSubscription.subscription_starts_at | datefilter }} &nbsp;&nbsp;-&nbsp;&nbsp; {{ paymentSubscription.subscription_ends_at | datefilter }}</b>&nbsp;&nbsp;&nbsp;&nbsp;
                Current payment method: &nbsp;<b>{{ paymentSubscription.payment_method.name }}</b>
              </v-toolbar>
              <v-toolbar flat class="mb-4 px-3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('paymentHistory.search')"
                  @input="initialize(1)"
                  hide-details
                  outlined
                ></v-text-field>
                <v-spacer />
                <v-autocomplete
                  v-model="parentId"
                  :items="getMembersList"
                  item-text="company_name"
                  item-value="id"
                  :label="$t('paymentHistory.member')"
                  outlined
                  hide-details
                  :value="parentId"
                  @change="initialize(1)"
                  return-value
                ></v-autocomplete>
              </v-toolbar>
            </template>
            <!-- <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12"> </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("paymentHistory.parentId") }}</b> :
                    {{ item.user.id }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("paymentHistory.companyName") }}</b> :
                    {{ item.user.name }}
                  </v-col>
                </v-row>
              </td>
            </template> -->
            <template v-slot:item.amount="{ item }">
              {{ item.amount | USCurrencyfilter }}
            </template>
            <template v-slot:item.starts_at="{ item }">
              {{ item.starts_at }}/{{ item.ends_at }}
            </template>
            <template v-slot:item.paid_at="{ item }">
              {{ item.paid_at | datefilter }}
            </template>
            <template v-slot:item.receipt_url="{ item }">
              <v-btn
                v-if="item.payment_charge_id"
                small
                @click="getReceiptUrl(item.payment_charge_id)"
                dense
                class="v-btn v-size--default green darken-4 white--text"
                elevation="4"
              >
                {{ $t("paymentHistory.viewReceipt") }}
              </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-if="paymenthistory.length > 0"
              v-model="meta.current_page"
              :length="meta.last_page"
              @input="initialize"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VIEW_USER, MANAGE_USER } from "@/constants/permission";
import { showSnackbar } from "../eventbus/action.js";
export default {
  name: "PaymentHistory",
  components: {},
  data() {
    return {
      parentId: "",
      manageUser: false,
      viewUser: false,
      defaultItem: {
        user_detail: {},
      },
      loading: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 50,
      expanded: [],
      search: "",
      singleExpand: false,
      headers: [
        // { text: "", value: "data-table-expand", width: "5%" },
        {
          text: this.$t("paymentHistory.id"),
          align: "start",
          value: "id",
          width: "5%",
        },
        {
          text: this.$t("paymentHistory.paymentToken"),
          value: "payment_token",
          width: "20%",
        },
        {
          text: this.$t("paymentHistory.startEndDate"),
          value: "starts_at",
          width: "20%",
        },
        {
          text: this.$t("paymentHistory.paymentMethod"),
          value: "payment_method.name",
          width: "15%",
        },
        {
          text: this.$t("paymentHistory.amount"),
          value: "amount",
          width: "10%",
        },
        {
          text: this.$t("paymentHistory.paidAt"),
          value: "paid_at",
          width: "10%",
        },
        {
          text: this.$t("paymentHistory.receipt"),
          value: "receipt_url",
          width: "10%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      members: "members/getMembersList",
      paymenthistory: "paymenthistory/getPaymentHistory",
      meta: "paymenthistory/getPaymentHistoryMeta",
      paymentSubscription: "paymenthistory/getPaymentSubscription",
      paymentReceiptUrl: "paymenthistory/getPaymentReceiptUrl",
      user: "user/getUser",
    }),

    getMembersList() {
      return [
        // {
        //   id: "",
        //   company_name: this.$t("paymentHistory.allPayments"),
        // },
        ...this.members,
      ];
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.parentId = Number(this.$route.query.id);
    }
    this.initialize(1);
  },
  methods: {
    ...mapActions({
      getPaymentHistory: "paymenthistory/getPaymentHistory",
      getPaymentSubscription: "paymenthistory/getPaymentSubscription",
      getPaymentReceiptUrl: "paymenthistory/getPaymentReceiptUrl",
      setHeaderMenuItems: "common/setHeaderMenuItems",
      activateMember: "members/activateMember",
      getMembers: "members/getMembersList",
    }),

    async initialize(page) {
      this.page = page;
      let menuItems = [
        { title: this.$t("commonLayout.members"), path: "/pages/members" },
        {
          title: this.$t("commonLayout.associates"),
          path: "/pages/associates",
        },
        // {
        //   title: this.$t("commonLayout.paymenthistory"),
        //   path: "/pages/payment-history",
        // },
      ];
      const userPermission = this.user.permission.map((val) => val.name);
      this.manageUser = userPermission.includes(MANAGE_USER);
      this.viewUser = userPermission.includes(VIEW_USER);
      if (!this.viewUser) {
        menuItems = [];
      }
      this.setHeaderMenuItems(menuItems);
      this.loading = true;
      try {
        let filter = {};
        if (this.parentId && this.parentId !== "") {
          filter = { parent_id: this.parentId };
        }
        await this.getMembers();
        await this.getPaymentSubscription(filter);
        console.log(this.paymentSubscription);
        await this.getPaymentHistory({
          search: this.search,
          page: this.page,
          ...filter,
        });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },

    async getReceiptUrl(payment_charge_id) {
      this.loading = true;
      try {
        await this.getPaymentReceiptUrl({
          payment_charge_id: payment_charge_id
        });

        if(this.paymentReceiptUrl.data) {
          window.open(this.paymentReceiptUrl.data, '_blank');
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
  },

  destroyed() {
    this.setHeaderMenuItems([]);
  },
};
</script>
